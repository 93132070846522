<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAgent(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <agent-overview
      :agent="agent"
    />
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mt-1 mb-2 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span
                style="min-width: 130px;"
                v-text="$t('account')"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.account"
              autocomplete="off"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData(1)"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_balance).toLocaleString()" />
          <span v-text="$t('grand_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_balance_of_child_agent).toLocaleString()" />
          <span v-text="$t('grand_balance_of_child_agent')" />
        </b-card>
      </b-col>
      <b-col
        sm="4"
        md="4"
        lg="4"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.grand_total_balance).toLocaleString()" />
          <span v-text="$t('grand_total_balance')" />
        </b-card>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th class="vgt-left-align">
                        <span v-text="$t('account')" />
                      </th>
                      <th class="vgt-right-align">
                        <span v-text="$t('balance')" />
                      </th>
                      <th
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="$t('balance_of_child_agent')" />
                      </th>
                      <th
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="$t('total_balance')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in users"
                      :key="item.id"
                    >
                      <!-- Account -->
                      <td class="vgt-left-align">
                        <a
                          v-if="item.role !== 'member'"
                          href="#"
                          class="align-middle ml-50"
                          @click="loadAgent(item.id)"
                          v-text="item.account"
                        />
                        <span
                          v-else
                          v-text="item.account"
                        />
                      </td>
                      <!-- Balance -->
                      <td class="vgt-right-align">
                        <span v-text="numberFormatter(item.balance)" />
                      </td>
                      <!-- Child Balance -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="numberFormatter(item.childs_balance)" />
                      </td>
                      <!-- Total Balance -->
                      <td
                        v-if="agent.role !== 'partner'"
                        class="vgt-right-align"
                      >
                        <span v-text="numberFormatter(item.total_balance)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'

import 'vue-good-table/dist/vue-good-table.css'

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BFormInput,
} from 'bootstrap-vue'

import Account from '@/models/Account'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportBalance',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BFormInput,
    AgentOverview,
    CustomPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      users: [],
      report: {
        grand_balance: 0,
        grand_balance_of_child_agent: 0,
        grand_total_balance: 0,
      },
      agent: {
        id: '',
        username: '',
        currency: '',
        commission: 0,
        share: 0,
      },
      loading: false,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
      },
      agentLines: [],
      perPage: 10,
      totalItems: 0,
      loadAgent(id) {
        this.filter.agent = id
        this.filter.account = ''
        this.loadData(1)
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    numberFormatter(amount) {
      const formatNumber = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0, // Prevent decimals
      })
      return formatNumber.format(amount)
    },
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.balanceList(searchParams)
      this.users = response.data.data
      this.report = response.data.meta.report
      this.agent = response.data.meta.agent
      this.totalItems = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.agentLines = response.data.meta.agent_lines
      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
  },
}
</script>
